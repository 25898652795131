import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { ToastrModule } from 'ngx-toastr';
import { NgxMaskModule, IConfig } from 'ngx-mask';

import { SupportFooterComponent } from './components/support-footer/support-footer.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ModalComponent } from './components/modal/modal.component';
import { ListFilterPipe } from './pipes/list.filter.pipe';
import { PaginationComponent } from './components/pagination/pagination.component';
import { SafePipe } from './pipes/safe.pipe';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ReplaceElementPipe } from './pipes/replace-element.pipe';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { RatingComponent } from './components/rating/rating.component';
import { BazaarvoiceRatingComponent } from './components/bazaarvoice-rating/bazaarvoice-rating.component';
import { ZipCodeValidatorDirective } from './directives/zip-code-validator.directive';
import { MatchValueDirective } from './directives/match-value.directive';
import { FreeShippingComponent } from './components/free-shipping/free-shipping.component';
import { SafeHtmlPipe } from './pipes/safeHtml.pipe';
import { CvvCodeValidatorDirective } from './directives/cvv-code-validator.directive';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NumberOnlyDirective } from './directives/number-only.directive';
import { RouterModule } from '@angular/router';
import { AddToCartComponent } from './components/add-to-cart/add-to-cart.component';
import { ImageLazyLoadDirective } from './directives/image-lazy-load.directive';
import { ScrollToTopComponent } from './components/scroll-to-top/scroll-to-top.component';
import { NoWhitespaceDirective } from './directives/no-whitespace.directive';
import { BackgroundLazyLoadDirective } from './directives/background-lazy-load.directive';
import { JsonLdComponentComponent } from './components/json-ld-component/json-ld-component.component';

export let options: Partial<IConfig> | (() => Partial<IConfig>);

@NgModule({
  declarations: [
    SupportFooterComponent,
    PageNotFoundComponent,
    LoaderComponent,
    ModalComponent,
    ListFilterPipe,
    PaginationComponent,
    SafePipe,
    SafeHtmlPipe,
    ReplaceElementPipe,
    ClickOutsideDirective,
    RatingComponent,
    BazaarvoiceRatingComponent,
    ZipCodeValidatorDirective,
    MatchValueDirective,
    FreeShippingComponent,
    CvvCodeValidatorDirective,
    NumberOnlyDirective,
    AddToCartComponent,
    ImageLazyLoadDirective,
    ScrollToTopComponent,
    NoWhitespaceDirective,
    BackgroundLazyLoadDirective,
    JsonLdComponentComponent,
  ],
  imports: [
    CommonModule,
    NgxPaginationModule,
    SlickCarouselModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-full-width',
      enableHtml: true,
      closeButton: true,
      disableTimeOut: true,
      preventDuplicates: true,
      tapToDismiss: false,
    }),
    // Removed for AOT implementation
    NgxMaskModule.forRoot()
  ],
  exports: [
    NgxPaginationModule,
    NgxMaskModule,
    SlickCarouselModule,
    SupportFooterComponent,
    PageNotFoundComponent,
    LoaderComponent,
    ModalComponent,
    ListFilterPipe,
    SafePipe,
    SafeHtmlPipe,
    PaginationComponent,
    RatingComponent,
    BazaarvoiceRatingComponent,
    CvvCodeValidatorDirective,
    ReplaceElementPipe,
    ClickOutsideDirective,
    ZipCodeValidatorDirective,
    MatchValueDirective,
    NumberOnlyDirective,
    NoWhitespaceDirective,
    FreeShippingComponent,
    AddToCartComponent,
    ImageLazyLoadDirective,
    ScrollToTopComponent,
    BackgroundLazyLoadDirective,
    JsonLdComponentComponent,
  ]
})
export class SharedModule { }
