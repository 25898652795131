import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'listFilter',
    standalone: false
})
export class ListFilterPipe implements PipeTransform {
  // Pass 3 arguments for using below pipe
  // 1. Field to be compared
  // 2. Comparison Operator (equal, notequal)
  // 3. Value to be compared with
  transform(value: any, args?: any): any {
    if (value == null) {
      return;
    }

    return value.filter((item, index) => {
      if (args[1] !== 'equal') {
        // returns not equal items
        if (item[args[0]] !== args[2]) {
          return item;
        }
      } else {
        // returns equal (matching) items
        if (item[args[0]] === args[2]) {
          return item;
        }
      }
    });
  }
}
