import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Constants } from '../../constants';

@Component({
    selector: 'support-footer',
    templateUrl: './support-footer.component.html',
    styleUrls: ['./support-footer.component.css'],
    standalone: false
})
export class SupportFooterComponent implements OnInit {
  @ViewChild('supportFooter', { static: false }) supportFooter!: ElementRef;
  helpCenterURL: string = Constants.helpCenter;

  constructor(private renderer: Renderer2) { }

  ngOnInit() {
  }
}
