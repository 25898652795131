import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-rating',
    templateUrl: './rating.component.html',
    styleUrls: ['./rating.component.css'],
    standalone: false
})
export class RatingComponent implements OnInit {
  @Input() rating: any;
  @Input() showRatingCount?: boolean = true;

  constructor() { }

  ngOnInit() {
  }

}
