<div class="relative">
  <div class="opacity-0 absolute">rating {{ rating }} out of 5 stars</div>
  <span aria-hidden="true" class="text-sm text-gold" *ngIf="rating">
    <i class="fa fa-star" *ngIf="rating >= 1"></i>
    <i class="fa fa-star" *ngIf="rating >= 2"></i>
    <i class="fa fa-star" *ngIf="rating >= 3"></i>
    <i class="fa fa-star" *ngIf="rating >= 4"></i>
    <i class="fa fa-star" *ngIf="rating >= 5"></i>
    <i class="fa fa-star-half"
      *ngIf="rating % 2 >= 0.5 &&
        rating != 5 &&
        rating != 4 &&
        rating != 3 &&
        rating != 2 &&
        rating != 1"
    ></i>
    <strong class="text-sm" *ngIf="showRatingCount">({{ rating }})</strong>
  </span>
</div>
