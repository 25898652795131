
<div class="md:flex">

  <div class="md:w-1/2 photo-half-modal__image"
    style="background-image: url(../assets/img/free-shipping-modal-photo.jpg)">

  </div>

  <div class="md:w-1/2 p-8 lg:p-16">

    <div class="uppercase text-30px font-display lines-left-red mb-4 md:mb-0">Free Shipping!</div>

    <!-- <p>Free shipping on orders over $99! Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nisl ex,
      iaculis ut ante nec, vulputate consequat risus.</p> -->

    <a href="#" class="inline-block mt-1 button button--fill w-full md:w-auto">Shop Now</a>

  </div>

</div>