import { isPlatformServer } from '@angular/common';
import { Component, Inject, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import { RESPONSE } from '../../express.tokens';
import { Response } from 'express';
import { TitleMetadataService } from '../../services/title-metadata.service';

@Component({
    selector: 'app-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.css'],
    standalone: false
})
export class PageNotFoundComponent implements OnInit {
  private response: Response;

  constructor(
    @Inject(PLATFORM_ID) private platform: any,
    @Optional() @Inject(RESPONSE) response: any,
    private titleMetadataService: TitleMetadataService) {
    this.response = response;
  }

  ngOnInit() {
    this.titleMetadataService.updateTitle('Page Not Found - Jamestown Distributors');
    if (isPlatformServer(this.platform)) {
      console.log('410 - Page Not Found');
      if (this.response) {
        this.response.statusMessage = 'Page Not Found';
        this.response.statusCode = 410;
      }
    }
  }

}
