import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Constants } from '../../constants';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.css'],
    standalone: false
})
export class PaginationComponent implements OnInit {
  p: number = 1;
  @Input() currentPage: number;
  @Input() prevPageURL: string;
  @Input() nextPageURL: string;
  @Input() currentRouteURL: string;
  @Input() extraFilter: string;
  @Input() totalPages: number;
  @Output() pageChange: EventEmitter<any>;
  pageSize = Constants.pageSize;

  constructor() {
    this.pageChange = new EventEmitter<any>();
  }

  ngOnInit() {
  }

  getPaginationString(page) {
    if (page && page.value !== 1) {
      return `${this.currentRouteURL}?page=${page.value}${this.extraFilter}`;
    } else {
      let url = this.currentRouteURL;
      if (this.extraFilter) {
        url += `?${this.extraFilter}`;
      }
      return url;
    }
  }
}
