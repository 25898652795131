import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-bazaarvoice-rating',
    templateUrl: './bazaarvoice-rating.component.html',
    styleUrls: ['./bazaarvoice-rating.component.css'],
    standalone: false
})
export class BazaarvoiceRatingComponent implements OnInit {

  @Input() productId: any;

  constructor() { }

  ngOnInit() {
    setTimeout(() => {
      document.getElementById("bazaarvoice-inline-rating-" + this.productId).setAttribute("data-bv-product-id", this.productId);
    }) 
  }

}
