<pagination-template #p="paginationApi" (pageChange)="pageChange.emit($event)"
  class="pagination flex justify-between items-center py-8">
  <a [attr.aria-disabled]="p.isFirstPage()" [href]="[prevPageURL]" *ngIf="prevPageURL !== undefined && p.pages.length > 1; else prevButton"
    (click)="p.previous()"
    [ngStyle]="{'pointer-events':  p.isFirstPage() ? 'none' : 'auto', 'opacity':  p.isFirstPage() ? '0.5' : '1'}"
    class="uppercase font-display no-underline text-black text-22px">
    <i class="fa fa-long-arrow-alt-left text-blue"></i>
    <span>Previous</span>
  </a>
  <ng-template #prevButton>
    <button *ngIf="p.pages.length > 1" [disabled]="p.isFirstPage()"
      [ngStyle]="{'cursor':  p.isFirstPage() ? 'default' : 'pointer'}"
      class="uppercase font-display no-underline text-black text-22px" (click)="p.previous()">
      <i class="fa fa-long-arrow-alt-left text-blue"></i> <span>Previous</span>
    </button>
  </ng-template>

  <div class="xs:block sm:block md:block lg:hidden">
    {{ currentPage }} of {{ totalPages ? totalPages : 1 }}
  </div>

  <div *ngIf="p.pages.length > 1" class="pagination__numbers hidden lg:block">
    <span *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
      <span *ngIf="currentRouteURL !== undefined; else normalPagination">
        <a [href]="[getPaginationString(page)]" (click)="p.setCurrent(page.value)" 
        [attr.aria-label]="p.getCurrent() == 1 && page.value == 2 ? ('Go to page ' + page.value) : (p.getCurrent() - 1) == page.value ? 'Go to previous page ' + page.value : (p.getCurrent() + 1) == page.value ? 'Go to Next page ' + page.value : 'Go to page ' + page.value"
          *ngIf="p.getCurrent() !== page.value; else pageLabel" class="no-underline">
          <span class="no-underline text-grey-dark">{{ page.label }}</span>
        </a>
      </span>
      <ng-template #normalPagination>
        <a (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value; else pageLabel"
          class="no-underline">
          <span class="no-underline text-grey-dark">{{ page.label }}</span>
        </a>
      </ng-template>
      <ng-template #pageLabel>
        <strong>{{ page.label }}</strong>
      </ng-template>
    </span>
  </div>

  <a [attr.aria-disabled]="p.isLastPage()" [href]="[nextPageURL]" *ngIf="nextPageURL !== undefined && p.pages.length > 1;else nextButton" (click)="p.next()"
    [ngStyle]="{'pointer-events':  p.isLastPage() ? 'none' : 'auto', 'opacity':  p.isLastPage() ? '0.5' : '1'}"
    class="uppercase font-display no-underline text-black text-22px"><span> Next</span>
    <i class="fa fa-long-arrow-alt-right text-blue"></i>
  </a>
  <ng-template #nextButton>
    <button *ngIf="p.pages.length > 1" [disabled]="p.isLastPage()"
      [ngStyle]="{'cursor':  p.isLastPage() ? 'default' : 'pointer'}"
      class="uppercase font-display no-underline text-black text-22px" (click)="p.next()"><span>
        Next</span>
      <i class="fa fa-long-arrow-alt-right text-blue"></i>
    </button>
  </ng-template>

</pagination-template>