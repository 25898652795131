import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'app-json-ld-component',
    templateUrl: './json-ld-component.component.html',
    styleUrls: ['./json-ld-component.component.css'],
    standalone: false
})
export class JsonLdComponentComponent {
  @Input() json: any; // Input JSON-LD schema
  jsonLdScript: SafeHtml;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
    if (this.json) {
      const jsonStr = JSON.stringify(this.json).replace(/<\/script>/g, '<\\/script>');
      this.jsonLdScript = this.sanitizer.bypassSecurityTrustHtml(
        `<script type="application/ld+json">${jsonStr}</script>`
      );
    }
  }
}
