import { Directive, ElementRef, AfterViewInit, HostBinding, Input, HostListener } from '@angular/core';
import { Constants } from '../constants';

@Directive({
    selector: '[appImageLazyLoad]',
    standalone: false
})
export class ImageLazyLoadDirective implements AfterViewInit {
  @HostBinding('attr.src') srcAttr = null;
  @Input() src: string;

  constructor(private el: ElementRef) { }

  ngAfterViewInit() {
    this.canLazyLoad() ? this.lazyLoadImage() : this.loadImage();
  }

  private canLazyLoad() {
    return window && 'IntersectionObserver' in window;
  }

  private lazyLoadImage() {
    const obs = new IntersectionObserver(entries => {
      entries.forEach(({ isIntersecting }) => {
        if (isIntersecting) {
          this.loadImage();
          obs.unobserve(this.el.nativeElement);
        } else {
          this.srcAttr = Constants.loaderImagePath;
        }
      });
    });
    obs.observe(this.el.nativeElement);
  }

  private loadImage() {
    this.srcAttr = this.src;
  }

  @HostListener('error')
  loadImageOnError() {
    const element: HTMLImageElement = <HTMLImageElement>this.el.nativeElement;
    element.src = Constants.noImagePath;
  }

}
