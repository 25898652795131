<!-- Add to Cart -->
<div [ngClass]="{'nocursor': isButtonDisabled}">
    <button *ngIf="!isIconButton; else iconButton" [disabled]="isButtonDisabled" [ngClass]="[cssClasses]" (click)="addToCart()">ADD TO CART</button>
</div>

<ng-template #iconButton>
    <button class="button button--fill button--small" [disabled]="isButtonDisabled" [ngClass]="[cssClasses]" (click)="addToCart()">
        <span class="md:hidden"><i class="fal fa-shopping-cart"></i></span>
        <span class="hidden md:block">Add To Cart</span>
    </button>
</ng-template>
