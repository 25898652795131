import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-free-shipping',
    templateUrl: './free-shipping.component.html',
    styleUrls: ['./free-shipping.component.css'],
    standalone: false
})
export class FreeShippingComponent implements OnInit {
  active = true;
  @Input() modalView: boolean = false;
  @Output() closeModal = new EventEmitter();

  constructor() { }


   //Toogle Modal
   toggleModal(): void {
    this.active = true;
  }
  ngOnInit() {
  }

}
