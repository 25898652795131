<div #supportFooter class="support-footer bg-none" appBackgroundLazyLoad>
    <div class="mx-auto py-8 text-center support-footer__container">

        <div class="text-center uppercase text-30px font-display support-footer__title lines-centered-red">24/7 Support
        </div>

        <p>Need help or have questions? The FAQs in our Help Center cover most account, order, or product-related topics. Click to call or chat with our Total Support Team.</p>

        <div class="md:flex mx-auto my-6 justify-center">

            <!-- <a onclick="zE.activate()" class="button button--fill block md:w-full mb-3 ">Live Chat</a> -->

            <a [href]="helpCenterURL | safe" target="_blank" class="button button--fill block md:w-1/2 mb-3">Help
        Center</a>

        </div>

        <div class="text-center">
            <strong>or call <a href="tel:18004970010"> 1.800.497.0010</a></strong>
        </div>

    </div>
</div>