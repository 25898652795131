import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.css'],
    standalone: false
})
export class ModalComponent {
  // Bit filed to hide/show the modal
  @Input() active = false;
  @Input() isModalCancelBar = false;
  @Input() isModalBottom = false;

  // HTML Template
  @Input() template: any;

  constructor() {
  }

  // Toggle Modal
  toggleModal(): void {
    this.active = !this.active;
  }

}
