import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
    selector: '[appBackgroundLazyLoad]',
    standalone: false
})
export class BackgroundLazyLoadDirective {
  private observer: IntersectionObserver | undefined;

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    this.observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.elementRef.nativeElement.classList.remove('bg-none');
          this.observer!.unobserve(this.elementRef.nativeElement);
        }
      });
    }, options);

    this.observer.observe(this.elementRef.nativeElement);
  }
}
