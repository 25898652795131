import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NotificationService } from '../../services/notification.service';
import { CartService } from './../../../cart/cart.service';
import { UseraccountService } from './../../../useraccount/useraccount.service';
import { GoogleAnalyticsService } from './../../services/google-analytics.service';
import { StorageService } from './../../services/storage.service';
import { Constants } from '../../constants';
import { LoginService } from './../../../login/login.service';
import { Subscription } from 'rxjs';
import { BloomreachService } from '../../services/bloomreach.service';

@Component({
    selector: 'app-add-to-cart',
    templateUrl: './add-to-cart.component.html',
    styleUrls: ['./add-to-cart.component.css'],
    standalone: false
})
export class AddToCartComponent implements OnInit {
  @Input() selectedSKU: any;
  @Input() quantity: number;
  @Input() cssClasses: string;
  @Input() isIconButton: boolean = false;
  @Input() isButtonDisabled: boolean = true;
  @Output() getShoppingCartByIdData = new EventEmitter();
  isLoggedIn: boolean = false;
  userSub: Subscription;

  constructor(
    private notificationService: NotificationService,
    private cartService: CartService,
    private useraccountService: UseraccountService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private storageService: StorageService,
    private loginService: LoginService,
    private bloomreachService: BloomreachService) { }

  ngOnInit() {
    this.userSub = this.loginService.user.subscribe(user => {
      if (!!user) {
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = false;
      }
    });
  }

  addToCart(): void {
    const customerId = this.storageService.getItem('customerId');
    this.quantity = +this.quantity;
    if (isNaN(this.quantity) || this.quantity === undefined || this.quantity <= 0) {
      this.notificationService.showError('Please enter valid quantity');
      return;
    }
    const productDetail = {
      skus: [
        {
          id: this.selectedSKU.id,
          quantity: this.quantity,
          uom: this.selectedSKU.uom,
          uomQty: this.selectedSKU.uomQty
        }
      ]
    };
    if (this.isLoggedIn) {
      if (customerId) {
        this.addProductToCart(productDetail);
      } else {
        const body = {
          accountId: this.storageService.getItem('accountId')
        };
        // Create Customer Account as Customer account does not exist
        this.useraccountService.createCustomer(body).subscribe(response => {
          this.storageService.setItem('customerId', response.id);
          this.addProductToCart(productDetail);
        }, error => {
          this.notificationService.showError(error);
        });
      }
    } else {
      // Post Cart Recreate List to API in case of without login
      const cartId = this.storageService.getItem('cartId');
      if (cartId) {
        // update existing shopping cart without account
        this.cartService.createShoppingCartSkuWithoutAccount(cartId, productDetail.skus[0]).subscribe((response) => {
          if (response.id) {
            this.notificationService.showSuccess('Added to your Shopping Cart');
            const data = {
              skus: [response]
            };
            this.bloomreachService.trackCartUpdate(response);
            this.googleAnalyticsService.emitCartEventTracking(Constants.googleAnalytics.eventTags.addToCart, data);
            this.getShoppingCartByIdData.emit();
            this.cartService.onCartChange.next(true);
          } else {
            this.notificationService.showError('No responseid');
          }
        }, (error) => {
          this.notificationService.showError(error);
        });
      } else {
        // Create new shopping cart without account
        this.cartService.createShoppingCartWithoutAccount(productDetail).subscribe((response) => {
          if (response.id) {
            this.storageService.setItem('cartId', response.id);
            this.notificationService.showSuccess('Added to your Shopping Cart');
            this.bloomreachService.trackCartUpdate(response);
            this.googleAnalyticsService.emitCartEventTracking(Constants.googleAnalytics.eventTags.addToCart, response);
            this.getShoppingCartByIdData.emit();
            this.cartService.onCartChange.next(true);
          } else {
            this.notificationService.showError('No responseid');
          }
        }, (error) => {
          this.notificationService.showError(error);
        });
      }
    }
  }

  addProductToCart(productDetail) {
    const cartId = this.storageService.getItem('cartId');
    if (cartId !== null && cartId !== undefined) {
      this.cartService.createShoppingCartSku(cartId, productDetail.skus[0]).subscribe((response) => {
        this.getShoppingCartByIdData.emit();
        this.cartService.onCartChange.next(true);
        this.notificationService.showSuccess('Added to your Shopping Cart');
        const data = {
          skus: [response]
        };
        this.bloomreachService.trackCartUpdate(response);
        this.googleAnalyticsService.emitCartEventTracking(Constants.googleAnalytics.eventTags.addToCart, data);
      }, (error) => {
        this.notificationService.showError(error);
      });
    } else {
      this.cartService.createShoppingCart(productDetail).subscribe((response) => {
        if (response.id) {
          this.storageService.setItem('cartId', response.id);
          this.notificationService.showSuccess('Added to your Shopping Cart');
          this.bloomreachService.trackCartUpdate(response);
          this.googleAnalyticsService.emitCartEventTracking(Constants.googleAnalytics.eventTags.addToCart, response);
          this.getShoppingCartByIdData.emit();
          this.cartService.onCartChange.next(true);
        } else {
          this.notificationService.showError('No response.id');
        }
      }, (error) => {
        this.notificationService.showError(error);
      });
    }
  }
}
